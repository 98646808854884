import React from 'react';
import { Col, Card } from 'react-bootstrap';

const Campo = props => {
  const { label = '', valor = '' } = props;
  return (
    <Col>
      <Card.Text>
        <span className="fw-bold">{label}</span> {valor}
      </Card.Text>
    </Col>
  );
};

export default Campo;
