import env from './env';

const build = endpoint => `${env.URL_API}${endpoint}`;

const api = {
  INFO: { url: () => build('/v1-sa/info'), metodo: 'GET' },
  IS_FUNCI: { url: email => build(`/v1-sa/funci/isfunci/${email}`), metodo: 'GET' },
  LOGS: { url: () => build('/v1/funci/logs'), metodo: 'POST' },
  TURNOS: { url: () => build('/v1/funci/turnos'), metodo: 'GET' },
  SOCIO_CONSULTAR: { url: titulo => build(`/v1/funci/socio/${titulo}`), metodo: 'GET' },
  SOCIO_E_DEP_BUSCAR: { url: titulo => build(`/v1/funci/socioedep/${titulo}`), metodo: 'GET' },
  PESSOAS_BUSCAR: {
    url: (criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) =>
      build(
        `/v1/funci/pessoas?criterio=${criterio}&pagina=${pagina}&qtdePorPagina=${qtdePorPagina}&campoOrdenacao=${campoOrdenacao}&sentidoOrdenacao=${sentidoOrdenacao}`,
      ),
    metodo: 'GET',
  },
  PESSOAS_CONSULTAR: { url: id => build(`/v1/funci/pessoa/${id}`), metodo: 'GET' },
  PESSOAS_INCLUIR: { url: () => build('/v1/funci/pessoa'), metodo: 'POST' },
  PESSOAS_FOTO: { url: id => build(`/v1/funci/foto/${id}`), metodo: 'GET' },
  CONVITES_DISP_AVULSO: { url: titulo => build(`/v1/funci/convites/disponivelAvulso/${titulo}`), metodo: 'GET' },
  CONVITES_DISP_CHURRAS: { url: (ano, titulo) => build(`/v1/funci/convites/disponivelChurrasqueira/${ano}/${titulo}`), metodo: 'GET' },
  CONVITES_DISP_CHURRAS_RESERVA: {
    url: (idReserva, ano) => build(`/v1/funci/convites/disponivelChurrasqueiraReservada/${idReserva}/${ano}`),
    metodo: 'GET',
  },
  CONVITES_EMITIR: { url: () => build(`/v1/funci/convites`), metodo: 'POST' },
  CONVITES_LISTAR: { url: titulo => build(`/v1/funci/convites/${titulo}`), metodo: 'GET' },
  CONVITES_LISTAR_CRITERIO: { url: criterio => build(`/v1/funci/convites-criterio/${criterio}`), metodo: 'GET' },
  CONVITE: { url: idConvite => build(`/v1/funci/convite/${idConvite}`), metodo: 'GET' },
  CONVITE_ENTRADA: { url: () => build(`/v1/funci/entradaConvidado`), metodo: 'POST' },
  CONVITE_ALTERAR: { url: () => build(`/v1/funci/convites`), metodo: 'PATCH' },
  CONVITE_EMAIL: { url: () => build(`/v1/funci/enviarconvite`), metodo: 'POST' },
  CHURRASQUEIRAS_LISTAR: { url: () => build(`/v1/funci/churrasqueiras`), metodo: 'GET' },
  CHURRASQUEIRAS_CONSULTAR: { url: idChurrasqueira => build(`/v1/funci/churrasqueiras/${idChurrasqueira}`), metodo: 'GET' },
  CHURRASQUEIRAS_ALTERAR: { url: () => build(`/v1/funci/churrasqueiras`), metodo: 'PATCH' },
  RESERVAS_LISTAR: { url: (dataInicial, dataFinal, titulo) => build(`/v1/funci/reservas/${dataInicial}/${dataFinal}/${titulo}`), metodo: 'GET' },
  RESERVAS_CONSULTAR: { url: idReserva => build(`/v1/funci/reserva/${idReserva}`), metodo: 'GET' },
  RESERVAS_ALTERAR: { url: () => build(`/v1/funci/reservas`), metodo: 'PATCH' },
  RESERVAS_DISP_CHURRASQ: { url: data => build(`/v1/funci/reservas/churrasqueirasDisponiveis/${data}`), metodo: 'GET' },
  RESERVAS_DISP_DATAS: { url: () => build(`/v1/funci/reservas/datasDisponiveis`), metodo: 'GET' },
  RESERVAS_CONVITES: { url: idReserva => build(`/v1/funci/convites-reserva/${idReserva}`), metodo: 'GET' },
  RESERVAS_EMITIR: { url: () => build(`/v1/funci/reservas`), metodo: 'POST' },
  USUARIOS_SIS_LISTAR: { url: titulo => build(`/v1/funci/usuarios-sistema/titulo/${titulo}`), metodo: 'GET' },
  USUARIOS_SIS_CONSULTAR: { url: uid => build(`/v1/funci/usuarios-sistema/uid/${uid}`), metodo: 'GET' },
  USUARIOS_SIS_ALTERAR: { url: () => build(`/v1/funci/usuarios-sistema`), metodo: 'PATCH' },
  USUARIOS_SIS_PERFIS_USUARIO: { url: uid => build(`/v1/funci/perfis-acesso/${uid}`), metodo: 'GET' },
  USUARIOS_SIS_PERFIS: { url: () => build(`/v1/funci/perfis-sistema`), metodo: 'GET' },
  ACESSOS_REGISTRO: { url: () => build(`/v1/funci/acessos-fisicos`), metodo: 'POST' },
  ACESSOS_LISTAR: {
    url: (dataInicial, dataFinal, tipoIdentificador, identificador) =>
      build(`/v1/funci/acessos-fisicos/${dataInicial}/${dataFinal}/${tipoIdentificador}/${identificador}`),
    metodo: 'GET',
  },
  EXAMES_BUSCAR: {
    url: (criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) =>
      build(`/v1/funci/exames/${criterio}/${pagina}/${qtdePorPagina}/${campoOrdenacao}/${sentidoOrdenacao}`),
    metodo: 'GET',
  },
  EXAMES_BUSCAR_POR_DATA: {
    url: (dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) =>
      build(`/v1/funci/exames-data/${dataInicial}/${dataFinal}/${pagina}/${qtdePorPagina}/${campoOrdenacao}/${sentidoOrdenacao}`),
    metodo: 'GET',
  },
  EXAMES_CONSULTAR: { url: id => build(`/v1/funci/exame/${id}`), metodo: 'GET' },
  EXAMES_ALTERAR: { url: () => build('/v1/funci/exame'), metodo: 'PATCH' },
  EXAMES_INCLUIR: { url: () => build('/v1/funci/exame'), metodo: 'POST' },
  RESTRICOES_CONSULTAR: {
    url: (criterio, dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) =>
      build(`/v1/funci/restricoes/${criterio}/${dataInicial}/${dataFinal}/${pagina}/${qtdePorPagina}/${campoOrdenacao}/${sentidoOrdenacao}`),
    metodo: 'GET',
  },
  RESTRICOES_INCLUIR: { url: () => build(`/v1/funci/restricoes`), metodo: 'POST' },
  RESTRICOES_ALTERAR: { url: () => build(`/v1/funci/restricoes`), metodo: 'PATCH' },
  BOLETOS_LISTAR: {
    url: (criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) =>
      build(
        `/v1/funci/boletos?criterio=${criterio}&pagina=${pagina}&qtdePorPagina=${qtdePorPagina}&campoOrdenacao=${campoOrdenacao}&sentidoOrdenacao=${sentidoOrdenacao}`,
      ),
    metodo: 'GET',
  },
  BOLETOS_CONSULTAR: {
    url: (idBoleto, consultarIf) => build(`/v1/funci/boletos/${idBoleto}?consultarIf=${consultarIf ? 'sim' : 'nao'}`),
    metodo: 'GET',
  },
  BOLETOS_ALTERAR: { url: idBoleto => build(`/v1/funci/boletos/${idBoleto}`), metodo: 'PATCH' },
  BOLETOS_INCLUIR: { url: () => build('/v1/funci/boletos'), metodo: 'POST' },
  BOLETOS_PDF: { url: idBoleto => build(`/v1-pdf/funci/boletospdf/boleto-${idBoleto}.pdf`), metodo: 'GET' },
  BOLETOS_EMAIL: { url: idBoleto => build(`/v1/funci/boletos/${idBoleto}?sendMail=sim`), metodo: 'POST' },
  /** APROVEITAR */
  TERCEIROS: { url: () => build('/v1/terceiros'), metodo: 'GET' },
  SALT: { url: email => build(`/v1/login/salt/${email}`), metodo: 'GET' },
  LOGIN: { url: () => build('/v1/login'), metodo: 'POST' },
  CONVITES_DISP_CHURRASQ: { url: (uid, ano) => build(`/v1/convites/disponivelChurrasqueira/${uid}/${ano}`), metodo: 'GET' },
};

export default api;
