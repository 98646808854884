import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useForm } from 'react-hook-form';

import { Carregando, Confirmacao, Foto, Paginacao } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarCPF, validarTipo, withOrdenar } from '../../utils';
import { modelPessoa } from '../../models';

export default withRouter(props => {
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const { handleSubmit, register, errors } = useForm();
  const [pessoas, setPessoas] = useState([]);
  const [records, setRecords] = useState('');

  const funci = new Funci();

  const [pesquisa, setPesquisa] = useState({
    criterio: '0',
    pagina: 1,
    qtdePagina: 25,
    campoOrdenacao: '0',
    sentidoOrdenacao: '0',
  });

  const onSubmitForm = async values => {
    const { paramCriterio } = values;
    // Validar quaisquer campos necessários se houver

    // Comandar a navagação paginada
    setPesquisa({ ...pesquisa, criterio: paramCriterio || '0' });
  };

  const registrarEntrada = async (local, idPessoaTitular, codDependente = null) => {
    if (!validarTipo(local, 'string')) return alertar('warning', 'Local de entrada inválido.');
    if (!validarTipo(idPessoaTitular, 'string')) return alertar('warning', 'Pessoa inválida.');
    if (codDependente && !validarTipo(codDependente, 'string')) return alertar('warning', 'Pessoa inválida.');

    setCarregando(true);
    fecharConfirmacao();

    try {
      const registro = await Funci.registrarAcessoFisico({ localAcesso: local, idPessoaTitular, codDependente });

      if (registro.erro) {
        alertar('danger', registro.erro);
      } else if (registro === true) {
        alertar('success', 'Registro de entrada realizado com sucesso.');
      } else {
        alertar('danger', 'Erro ao registrar a entrada do usuário.');
      }
    } catch (e) {
      console.log('e', e);
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const navegar = (crit, pag, qtde, campo, sentido) => {
    setCarregando(true);
    try {
      setPesquisa({
        ...pesquisa,
        criterio: crit,
        pagina: pag,
        qtdePagina: qtde,
        campoOrdenacao: campo,
        sentidoOrdenacao: sentido,
      });
    } catch (e) {
      alertar('error', e.msg || e);
    } finally {
      setCarregando(false);
    }
  };

  const ordenar = (campo, sentido) => {
    navegar(pesquisa.criterio, pesquisa.pagina, pesquisa.qtdePagina, campo, sentido);
  };

  const paginar = pag => {
    navegar(pesquisa.criterio, pag, pesquisa.qtdePagina, pesquisa.campoOrdenacao, pesquisa.sentidoOrdenacao);
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      setPessoas([]);
      try {
        const { criterio, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao } = pesquisa;
        if (!criterio || criterio === '0') return null;

        const consulta = await Funci.buscarPessoas(criterio || '0', pagina, qtdePagina, campoOrdenacao || '0', sentidoOrdenacao || '0');

        if (consulta.erro) {
          setPessoas([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setPessoas([]);
            alertar('warning', 'Nenhuma pessoa encontrada.');
          }
          if (consulta.length > 0) {
            setRecords(consulta[0].records);
            setPessoas(consulta.map(c => modelPessoa(c)));
          }
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [pesquisa, atualizar]);

  const statusColors = {
    ATIVO: 'text-success',
    INATIVO: 'text-secondary',
    CANCELADO: 'text-danger',
  };

  const exameColors = {
    S: 'text-success',
    N: 'text-danger',
    INVÁLIDO: 'text-danger',
    VENCIDO: 'text-danger',
  };

  const location = {
    pathname: '/novocadastro',
    state: { atualizarPesquisa: () => setAtualizar(atualizar + 1) },
  };

  return (
    <React.Fragment>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Row className="px-2">
        {/* {funci.hasPerfil('SECRETARIA') && (
          <Col xs="auto">
            <LinkContainer to={location}>
              <Button variant="success" className="mt-2">
                Novo cadastro
              </Button>
            </LinkContainer>
          </Col>
        )} */}
        <Col className="justify-content-end">
          <Form onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
            <Row className="justify-content-end">
              <Form.Group as={Col} xs={4} controlId="paramCriterio" className="align-middle mb-0">
                <Form.Control
                  name="paramCriterio"
                  type="text"
                  maxLength={50}
                  placeholder="Título, nome ou cpf"
                  ref={register({
                    minLength: { value: 1, message: 'A pesquisa deve conter no mínimo 1 caractere(s).' },
                    maxLength: { value: 50, message: 'A pesquisa deve conter no máximo 50 caracteres.' },
                  })}
                />
                <Col as={Card.Text} className="text-danger">
                  {errors.paramCriterio && errors.paramCriterio.message}
                </Col>
              </Form.Group>
              <Col xs="auto">
                <Button type="submit">Buscar</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th className="text-center">Foto</th>
            <th className="text-center">Id</th>
            <th className="text-center">Título</th>
            <th className="text-center">Categoria</th>
            <th className="text-center">Cód. Dep.</th>
            <th>Nome</th>
            <th className="text-center">CPF/CNPJ</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Exame Médico</th>
            <th className="text-center">Status</th>
            {funci.hasPerfil('SECRETARIA') && <th className="text-center">Detalhes</th>}
            {funci.hasPerfil(['PISCINA', 'PORTARIA']) && <th className="text-center">Entrada</th>}
          </tr>
        </thead>
        <tbody>
          {pessoas.map(p => (
            <tr key={`${p.id}`}>
              <td className="text-center align-middle">
                <div style={{ height: 150 }}>
                  <Foto id={`${p.id}`} height={150} />
                </div>
              </td>
              <td className="text-center align-middle">{p.id}</td>
              <td className="text-center align-middle">{p.titulo || ''}</td>
              <td className="text-center align-middle">{p.categoria}</td>
              <td className="text-center align-middle">{p.codDependente}</td>
              <td className="align-middle">
                {p.nome}
                {p.apelido && ` (${p.apelido})`}
              </td>
              <td className="text-center align-middle">{formatarCPF(p.cpfCnpj)}</td>
              <td className="text-center align-middle">{p.tipoLabel}</td>
              <td className={`${exameColors[p.exameMedico] || 'text-success'} text-center align-middle`}>{`${p.exameMedico}`}</td>
              <td className={`${statusColors[p.statusLabel] || 'text-secondary'} text-center align-middle`}>{p.statusLabel}</td>
              {funci.hasPerfil('SECRETARIA') && (
                <td className="text-center align-middle">
                  <LinkContainer to={{ pathname: `/detalhecadastro/${p.id}` }}>
                    <Badge>Clique aqui</Badge>
                  </LinkContainer>
                </td>
              )}
              {funci.hasPerfil(['PISCINA', 'PORTARIA']) && (
                <td className="text-center align-middle">
                  {funci.hasPerfil(['PORTARIA']) && (
                    <>
                      <span
                        className="icon-wrapper"
                        onClick={() =>
                          confirmar('Deseja registrar a entrada do sócio?', () => registrarEntrada('P10', `${p.idPessoa}`, p.codDependente))
                        }
                      >
                        <i style={{ width: 15, textAlign: 'center', marginRight: 6 }} className={`fa fa-sign-in`} />
                        Portaria Principal
                      </span>
                      <br />
                      <span
                        className="icon-wrapper"
                        onClick={() =>
                          confirmar('Deseja registrar a entrada do sócio?', () => registrarEntrada('P20', `${p.idPessoa}`, p.codDependente))
                        }
                      >
                        <i style={{ width: 15, textAlign: 'center', marginRight: 6 }} className={`fa fa-sign-in`} />
                        Portaria Veículos
                      </span>
                      <br />
                      <span
                        className="icon-wrapper"
                        onClick={() =>
                          confirmar('Deseja registrar a entrada do sócio?', () => registrarEntrada('S10', `${p.idPessoa}`, p.codDependente))
                        }
                      >
                        <i style={{ width: 15, textAlign: 'center', marginRight: 6 }} className={`fa fa-sign-in`} />
                        Sauna
                      </span>
                      <br />
                      <span
                        className="icon-wrapper"
                        onClick={() =>
                          confirmar('Deseja registrar a entrada do sócio?', () => registrarEntrada('C10', `${p.idPessoa}`, p.codDependente))
                        }
                      >
                        <i style={{ width: 15, textAlign: 'center', marginRight: 6 }} className={`fa fa-sign-in`} />
                        Churrasqueiras
                      </span>
                    </>
                  )}
                  {funci.hasPerfil(['PORTARIA']) && funci.hasPerfil(['PISCINA']) && <br />}
                  {funci.hasPerfil(['PISCINA']) && (
                    <>
                      <span
                        className="icon-wrapper"
                        onClick={() =>
                          confirmar('Deseja registrar a entrada do sócio na área de Piscina?', () =>
                            registrarEntrada('M01', `${p.idPessoa}`, p.codDependente)
                          )
                        }
                      >
                        <i style={{ width: 15, textAlign: 'center', marginRight: 6 }} className={`fa fa-sign-in`} />
                        Piscina Ilha e Olímpica
                      </span>
                      <br />
                      <span
                        className="icon-wrapper"
                        onClick={() =>
                          confirmar('Deseja registrar a entrada do sócio na área de Piscina?', () =>
                            registrarEntrada('I02', `${p.idPessoa}`, p.codDependente)
                          )
                        }
                      >
                        <i style={{ width: 15, textAlign: 'center', marginRight: 6 }} className={`fa fa-sign-in`} />
                        Piscina Aquecida
                      </span>
                    </>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Paginacao records={records} qtdePorPagina={pesquisa.qtdePagina} paginaAtual={pesquisa.pagina} paginar={paginar} />
      </div>
    </React.Fragment>
  );
});
