const ambiente = process.env.REACT_APP_ENV === 'development' ? 'development' : 'production';

const env = {
  SECRET: 'm1n4sS3cr3t',
  SERVIDOR: ambiente,
  URL_API: ambiente === 'development' ? 'https://api.minasbrasilia.com.br:3300' : 'https://api.minasbrasilia.com.br:3300',
  // URL_API: ambiente === 'development' ? 'https://3zsmartapps.dev.br:3299' : 'https://3zsmartapps.dev.br:3299',
  // URL_API: ambiente === 'development' ? 'https://api.minasbrasilia.com.br:3300' : 'http://192.168.0.8:3300',
  // URL_API: ambiente === 'development' ? 'http://192.168.0.9:3299' : 'http://192.168.0.9:3299',
  // URL_API: ambiente === 'development' ? 'http://localhost:3299' : 'http://localhost:3299',
  MENU_SEMPRE_EXPANDIDO: false,
  MENU: [
    { tipo: 'link', label: 'Home', tag: 'home', acesso: '', link: '/' },
    {
      tipo: 'agrupador',
      label: 'Gestão de Sócios',
      tag: 'socios',
      acesso: ['ADMIN', 'SECRETARIA'],
      itens: [{ tipo: 'link', label: 'Restrições', tag: 'restricoes', acesso: '', link: '/restricoes' }],
    },
    {
      tipo: 'agrupador',
      label: 'Clube',
      tag: 'clube',
      acesso: ['ADMIN', 'SECRETARIA'],
      itens: [
        { tipo: 'link', label: 'Acessos Físicos', tag: 'acessos-fisicos', acesso: '', link: '/acessos-fisicos' },
        { tipo: 'link', label: 'Convites', tag: 'convites', acesso: '', link: '/convites/0' },
        { tipo: 'link', label: 'Churrasqueiras', tag: 'churrasqueiras', acesso: '', link: '/churrasqueiras/reservas' },
      ],
    },
    {
      tipo: 'agrupador',
      label: 'Financeiro',
      tag: 'financeiro',
      acesso: ['ADMIN', 'FINANCEIRO'],
      itens: [{ tipo: 'link', label: 'Boletos', tag: 'boletos', acesso: '', link: '/boletos/avulsos' }],
    },
    { tipo: 'link', label: 'Exame Médico', tag: 'exame-médico', acesso: '', link: '/exames' },
    {
      tipo: 'agrupador',
      label: 'Administração do sistema',
      tag: 'admin-sistema',
      acesso: ['ADMIN'],
      itens: [{ tipo: 'link', label: 'Usuários', tag: 'usuarios', acesso: '', link: '/usuarios/0' }],
    },
    { tipo: 'link', label: 'Sair', tag: 'sair', acesso: '', link: '/logout' },
  ],
};

export default env;
