import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { BoletosAvulsos, BoletosLote } from '.';

export default () => {
  return (
    <Container fluid>
      <br />
      <Tabs defaultActiveKey="avulsos" id="uncontrolled-tab-example">
        {/** ************************************************************************************************************************************* */}
        {/** * BOLETOS                                                                                                                             */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="avulsos" title="Boletos Avulsos">
          <BoletosAvulsos />
        </Tab>
        {/** ************************************************************************************************************************************* */}
        {/** * LOTES                                                                                                                               */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="lote" title="Boletos em Lote">
          <br />
          <BoletosLote />
        </Tab>
      </Tabs>
    </Container>
  );
};
