import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form, Table, Badge } from 'react-bootstrap';
import { Voltar, Alerta, Carregando, Confirmacao } from '../../components';
import { useParams, withRouter } from 'react-router-dom';
import { Funci } from '../../services';
import modelReserva from '../../models/modelReserva';
import { LinkContainer } from 'react-router-bootstrap';
import modelConvite from '../../models/modelConvite';
import { withOrdenar } from '../../utils';

export default withRouter(props => {
  const { idReserva } = useParams();
  const { history, location } = props;
  const { novaReserva } = location.state || { novaReserva: false };
  const [atualizacao, setAtualizacao] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', onConfirm: () => {}, onCancel: () => {}, show: false });
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [ckAlteracao, setCkAlteracao] = useState(false);
  const [reserva, setReserva] = useState(null);
  const [convites, setConvites] = useState(null);
  const [churrasDisp, setChurrasDisp] = useState(null);
  const [selIdChurras, setSelIdChurras] = useState(0);

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const novoConvite = () => {
    history.push(`/novoconvitechurrasqueira/${idReserva || 0}`);
  };

  const consultarDisp = async indicador => {
    if (indicador) {
      try {
        fecharAlerta();
        fecharConfirmacao();
        setCarregando(true);
        const consulta = await Funci.listarChurrasqueirasDispPorData(moment(reserva.dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        if (consulta.erro) alertar('danger', consulta.erro);
        setCkAlteracao(true);
        if (consulta.length > 1) {
          setChurrasDisp(withOrdenar(consulta).ordenar('nome', 'A'));
        } else {
          alertar('warning', 'Não existem churrasqueiras disponíveis para troca nesta data.');
        }
        setCarregando(false);
      } catch (e) {
        alertar('danger', e);
        setCarregando(false);
      }
    } else {
      setCkAlteracao(false);
    }
  };

  const cancelarReserva = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      setCarregando(true);
      const alteracao = await Funci.cancelarReserva(idReserva);
      if (alteracao.erro) alertar('danger', alteracao.erro);
      if (alteracao === true) {
        alertar('success', 'Reserva cancelada com sucesso.');
        setCkAlteracao(false);
        return setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  const alterarReserva = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      setCarregando(true);
      const alteracao = await Funci.alterarChurrasReserva(idReserva, selIdChurras);
      if (alteracao.erro) alertar('danger', alteracao.erro);
      if (alteracao === true) {
        alertar('success', 'Reserva alterada com sucesso.');
        setCkAlteracao(false);
        return setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const reserv = await Funci.consultarReserva(idReserva);
        if (reserv.erro) return alertar('danger', reserv.erro);

        if (reserv.idReserva) {
          const convs = await Funci.listarConvitesPorIdReserva(idReserva);
          if (convs.erro) return alertar('danger', convs.erro);
          setReserva(modelReserva(reserv));
          if (convs.length > 0)
            setConvites(
              withOrdenar(convs.map(c => modelConvite(c)))
                .ordenar('timestamp', 'D', 'DD/MM/YYYY HH:mm:ss')
                .ordenar('status', 'A'),
            );
        } else {
          alertar('danger', 'Reserva não localizada.');
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [idReserva, atualizacao]);

  const { cancelamento = null } = reserva || {};

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Row className="justify-content-between">
        {novaReserva ? <Voltar to="/churrasqueiras/reservas" /> : <Voltar />}
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>
      {reserva ? (
        <Card>
          <Card.Header as="h6">{`Reserva`}</Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title>{`${reserva.objeto.nome}`}</Card.Title>
              </Col>
              <Col>
                <Row>
                  <Col>{`Categoria: ${reserva.objeto.categoriaLabel}`}</Col>
                  <Col>{`Capacidade: ${reserva.objeto.capacidade} pessoas`}</Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row className="border-bottom">
              <Col>
                <Card.Text className="font-weight-bold">Detalhes</Card.Text>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <Card.Text className="font-weight-bold">{reserva.nome}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card.Text>
                  {`Título: ${reserva.titulo}`}
                  <br />
                  {`Período: ${reserva.turnoLabel}`}
                  <br />
                  <span className={reserva.status === 'C' ? 'text-danger' : 'text-success'}>{`Status: ${reserva.statusLabel}`}</span>
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  {`Data reservada: ${reserva.dataInicial}`}
                  <br />
                  {`Data da solicitação: ${reserva.timestamp} (Solicitante - ${reserva.nomeResponsavel})`}
                  {cancelamento && (
                    <>
                      <br />
                      <span className="text-danger">{`Data de cancelamento: ${cancelamento.dataCancelamento} (Solicitante - ${cancelamento.solicitante})`}</span>
                    </>
                  )}
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row className="border-top pt-2">
              <Col>
                <Form.Check
                  inline="true"
                  type="checkbox"
                  id="R"
                  label="Trocar churrasqueira"
                  checked={ckAlteracao}
                  onChange={event => consultarDisp(event.target.checked)}
                />
              </Col>
              {churrasDisp && ckAlteracao ? (
                <Col>
                  <Form.Control as="select" value={selIdChurras} onChange={event => setSelIdChurras(event.target.value)}>
                    <option value={0}>Selecione uma churrasqueira...</option>
                    {churrasDisp.map(c => (
                      <option key={c.idChurrasqueira.toString()} value={c.idChurrasqueira}>
                        {c.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              ) : null}
            </Row>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  className="mx-2"
                  variant="danger"
                  disabled={reserva.status !== 'E' && reserva.status !== 'R'}
                  onClick={() => confirmar(`Tem certeza que deseja cancelar a reserva?`, cancelarReserva)}
                >
                  Cancelar Reserva
                </Button>
                <Button
                  className="mx-2"
                  variant="primary"
                  disabled={(reserva.status !== 'E' && reserva.status !== 'R') || !ckAlteracao}
                  onClick={() => confirmar(`Tem certeza que deseja alterar a reserva?`, alterarReserva)}
                >
                  Trocar churrasqueira
                </Button>
              </Col>
            </Row>
            <br />
            <Row className="border-top pt-4">
              <Col>
                <Card.Text className="font-weight-bold">Convites da reserva</Card.Text>
              </Col>
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      className="mx-2"
                      variant="success"
                      disabled={reserva.status !== 'E' && reserva.status !== 'R'}
                      onClick={() => novoConvite()}
                    >
                      Emitir convite
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row className="justify-content-center">
              {convites ? (
                <Table striped bordered hover responsive size="sm">
                  <thead>
                    <tr>
                      <th>CPF</th>
                      <th>Nome</th>
                      <th>Menor?</th>
                      <th>Veículo?</th>
                      <th>Descrição veículo</th>
                      <th>Placa</th>
                      <th>Status</th>
                      <th>Detalhes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {convites.map(c => (
                      <tr key={`${c.idConvite}`}>
                        <td>{c.cpf}</td>
                        <td>{c.nomeConvidado}</td>
                        <td>{c.menorAdolescente ? 'SIM' : 'NÃO'}</td>
                        <td>{c.veiculoAutorizado ? 'SIM' : 'NÃO'}</td>
                        <td>{c.descricaoVeiculo}</td>
                        <td>{c.placaVeiculo}</td>
                        <td className={`${{ A: 'text-primary', U: 'text-success', N: 'text-warning', C: 'text-danger' }[c.status]}`}>
                          {c.statusLabel}
                        </td>
                        <td>
                          <LinkContainer to={`/detalheconvite/${c.idConvite}`}>
                            <Badge variant="secondary">Clique aqui</Badge>
                          </LinkContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Card.Text>Nenhum convite emitido para a reserva.</Card.Text>
              )}
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
});
