import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, SelecaoSocioOuDemais, Confirmacao, Carregando } from '../../components';
import { alertar, formatarCEP, formatarValor, parseValor, validarCEP, validarCPF, validarValor } from '../../utils';
import { ufs } from '../../constants';
import { modelBoleto } from '../../models';
import { Funci } from '../../services';

export default withRouter(props => {
  const { history, location } = props;
  const { atualizarBoletos = () => {} } = location.state;
  const { handleSubmit, register, errors, control, reset, getValues, setValue, watch } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [showSelecaoSacado, setShowSelecaoSacado] = useState(false);
  const [sacado, setSacado] = useState({});
  const watchValor = watch('valor', 0);
  const watchDeducoes = watch('deducoes', 0);

  const onSubmitBoleto = dados => {
    const {
      enderecoLogradouro,
      enderecoComplemento,
      enderecoCidade,
      enderecoUF,
      enderecoCep,
      referencia,
      instrucoes,
      vencimento,
      valor,
      desconto,
      validadeDesconto,
      deducoes,
    } = dados;
    const { cpf, nome } = sacado;

    // Verificação dos campos obrigatórios
    if (!cpf || !validarCPF(cpf)) return alertar('warning', 'É necessário informar um sacado para o boleto.');
    if (!nome) return alertar('warning', 'É necessário informar um sacado para o boleto.');
    if (!enderecoLogradouro) return alertar('warning', 'É necessário informar o endereço do sacado.');
    if (!enderecoCidade) return alertar('warning', 'É necessário informar a cidade do sacado.');
    if (!enderecoUF) return alertar('warning', 'É necessário informar a UF do sacado.');
    if (!enderecoCep) return alertar('warning', 'É necessário informar o CEP do sacado.');
    if (!vencimento) return alertar('warning', 'É necessário informar o vencimento do boleto.');
    if (!valor) return alertar('warning', 'É necessário informar o valor original do boleto.');
    if (validadeDesconto && (!desconto || parseValor(desconto) === 0)) {
      return alertar('warning', 'Validade de desconto preenchida mas o valor do desconto não foi informado.');
    }
    if (parseValor(deducoes) >= parseValor(valor)) {
      return alertar('warning', 'Outras deduções devem ter valor inferior ao valor original do boleto.');
    }
    if (sacado.tipoSacado === 'socio' && !sacado.idPessoa) return alertar('danger', 'Erro ao obter as informações do sócio selecionado.');

    // Montar o objeto de boleto
    const { tipoSacado, idPessoa } = sacado;
    const inSocio = tipoSacado === 'socio' ? 'S' : 'N';
    const nrDiasLimRecebimento = 15;
    const boleto = modelBoleto({
      idConvenio: 1,
      inSocio,
      idPessoa: inSocio === 'S' ? idPessoa : null,
      tpInscricao: 1,
      cpfCnpj: cpf,
      nomeRazao: nome,
      enderecoLogradouro,
      enderecoComplemento,
      enderecoCidade,
      enderecoUf: enderecoUF,
      enderecoCep,
      dtEmissao: moment().format(),
      dtVcto: moment(vencimento).format(),
      vlOriginal: parseValor(valor),
      vlAbatimento: parseValor(deducoes),
      txObs: referencia,
      txInstrucoes: instrucoes,
      inAceite: 'N',
      inAceiteTituloVencido: 'S',
      nrDiasLimRecebimento,
      tpTitulo: 4,
      dsTipoTitulo: 'DS',
      inPermRecebParcial: 'N',
      descTp: parseValor(desconto) > 0 ? 1 : 0, // sem desconto
      descDtExp: parseValor(desconto) > 0 ? moment(validadeDesconto).format() : null,
      descValor: parseValor(desconto) > 0 ? parseValor(desconto) : null,
      jurosMoraTp: 2, // valor mensal
      jurosMoraPorcent: 1,
      multaTp: 2,
      multaPorcent: 2,
      multaData: moment(vencimento).add(1, 'days').format(),
    });
    confirmar('Tem certeza que deseja emitir o boleto com os dados preenchidos?', () => emitirBoleto(boleto));
  };

  const emitirBoleto = async boleto => {
    try {
      setCarregando(true);
      fecharConfirmacao();
      const emissaoBoleto = await Funci.incluirBoleto(boleto);
      if (emissaoBoleto.id) {
        atualizarBoletos();
        history.goBack();
      } else if (emissaoBoleto.erro) {
        alertar('danger', emissaoBoleto.erro);
      } else {
        alertar('error', 'Erro desconhecido na emissão do boleto.');
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const fecharSelecaoSacado = () => setShowSelecaoSacado(false);

  const selecionarSacado = payload => {
    fecharSelecaoSacado();
    setSacado({});
    const { tipoSacado } = payload;

    if (tipoSacado === 'cpf') {
      const { nome, cpf } = payload;
      if (!nome || !cpf) alertar('danger', 'Erro ao obter os dados básicos do sacado (Nome ou CPF).');
      const obj = {
        tipoSacado,
        cpf,
        nome: `${nome}`.toUpperCase(),
        enderecoLogradouro: '',
        enderecoComplemento: '',
        enderecoCidade: '',
        enderecoUF: '',
        enderecoCep: '',
      };
      setSacado(obj);
      reset(obj);
    } else if (tipoSacado === 'socio') {
      const { idPessoa, nome, cpf, enderecoLogradouro, enderecoComplemento, enderecoCidade, enderecoUF, enderecoCep } = payload;
      if (!nome) alertar('danger', 'Erro ao obter o nome sócio. Tente emitir o boleto informando o sacado manualmente.');
      if (!cpf) alertar('danger', 'CPF do sócio Inválido. Tente emitir o boleto informando o sacado manualmente.');
      const obj = {
        tipoSacado,
        idPessoa,
        nome: `${nome}`.toUpperCase(),
        cpf,
        enderecoLogradouro,
        enderecoComplemento,
        enderecoCidade,
        enderecoUF,
        enderecoCep,
      };
      setSacado(obj);
      reset(obj);
    } else {
      alertar('warning', 'Tipo de sacado inválido.');
    }
  };

  const validarDesconto = value => {
    if (getValues('validadeDesconto') === '') return true;
    if (!value) return false;
    if (!validarValor(value)) return false;
    if (parseValor(value) >= parseValor(getValues('valor')) + parseValor(getValues('deducoes'))) {
      alertar('warning', 'Valor do desconto não pode ser igual ou superior ao valor total do boleto.');
      return false;
    }
    return true;
  };

  const validarDataDesconto = value => {
    if (getValues('desconto') === '') return true;
    if (!value) return false;
    if (moment(value) < moment().startOf('day')) return false;
    if (moment(value) >= moment(getValues('vencimento')).startOf('day')) return false;
    return true;
  };

  useEffect(() => {
    let soma = 0;
    soma += parseValor(getValues('valor'));
    soma -= parseValor(getValues('deducoes'));
    setValue('total', formatarValor(soma));
  }, [watchValor, watchDeducoes, setValue, getValues]);

  return (
    <Container fluid>
      <br />
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <SelecaoSocioOuDemais show={showSelecaoSacado} titulo="Selecione um sacado" onCancel={fecharSelecaoSacado} onConfirm={selecionarSacado} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">Novo boleto avulso</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmitBoleto)} className="mt-2">
            <Row className="justify-content-between">
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="sacado">
                      <Form.Label className="mt-2 mb-0">
                        Sacado <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <br />
                      <Button onClick={() => setShowSelecaoSacado(true)}>Selecionar</Button>
                    </Form.Group>
                  </Col>
                  {sacado.cpf && (
                    <Col>
                      <Form.Group controlId="cpf" className="d-flex flex-column align-items-start">
                        <Form.Label className="mt-2 mb-0">
                          CPF <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          name="cpf"
                          defaultValue={sacado.cpf || ''}
                          ref={register({ required: 'É necessário selecionar um sacado.', validate: value => validarCPF(value) || 'CPF inválido.' })}
                        />
                        <Col as={Card.Text} className="text-danger">
                          {errors.cpf && errors.cpf.message}
                        </Col>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                {sacado.cpf && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group controlId="nome" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            Nome <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            name="nome"
                            defaultValue={sacado.nome || ''}
                            ref={register({ required: 'É necessário selecionar um sacado.' })}
                          />
                          <Col as={Card.Text} className="text-danger">
                            {errors.nome && errors.nome.message}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="enderecoLogradouro" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            Endereço <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            maxLength={50}
                            name="enderecoLogradouro"
                            defaultValue={sacado.enderecoLogradouro || ''}
                            ref={register({
                              required: 'É necessário informar um endereço do sacado.',
                              minLength: { value: 5, message: 'Endereço deve conter no mínimo 5 caracteres.' },
                              maxLength: { value: 50, message: 'Endereço de conter no máximo 50 caracteres.' },
                            })}
                          />
                          <Col as={Card.Text} className="text-danger">
                            {errors.enderecoLogradouro && errors.enderecoLogradouro.message}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="enderecoComplemento" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">Complemento</Form.Label>
                          <Form.Control type="text" name="enderecoComplemento" defaultValue={sacado.enderecoComplemento || ''} ref={register} />
                          <Col as={Card.Text} className="text-danger">
                            {errors.enderecoComplemento && errors.enderecoComplemento.message}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="enderecoCidade" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            Cidade <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            maxLength={40}
                            name="enderecoCidade"
                            defaultValue={sacado.enderecoCidade || ''}
                            ref={register({
                              required: 'É necessário informar a cidade do sacado.',
                              minLength: { value: 2, message: 'Cidade deve conter no mínimo 2 caracteres.' },
                              maxLength: { value: 50, message: 'Cidade de conter no máximo 40 caracteres.' },
                            })}
                          />
                          <Col as={Card.Text} className="text-danger">
                            {errors.enderecoCidade && errors.enderecoCidade.message}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs="2">
                        <Form.Group controlId="enderecoUF" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            UF <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Form.Select
                            aria-label="UF"
                            name="enderecoUF"
                            defaultValue={sacado.enderecoUF || ''}
                            ref={register({ required: 'É necessário informar UF do sacado.' })}
                          >
                            <>
                              <option value="">{''}</option>
                              {ufs.map(u => (
                                <option key={`${u.value}`} value={`${u.value}`}>{`${u.value}`}</option>
                              ))}
                            </>
                          </Form.Select>
                          <Col as={Card.Text} className="text-danger">
                            {errors.enderecoUF && errors.enderecoUF.message}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col xs="3">
                        <Form.Group controlId="enderecoCep" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            CEP <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Controller
                            render={props => (
                              <Form.Control
                                type="text"
                                placeholder="CEP"
                                value={props.value}
                                onChange={e => props.onChange(formatarCEP(e.target.value))}
                                ref={props.ref} // wire up the input ref
                              />
                            )}
                            name="enderecoCep"
                            defaultValue=""
                            control={control}
                            rules={{
                              required: 'É necessário informar o CEP do sacado.',
                              validate: value => validarCEP(value) || 'CEP inválido.',
                            }}
                          />
                          <Col as={Card.Text} className="text-danger">
                            {errors.enderecoCep && errors.enderecoCep.message}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <hr />
                    <Form.Group controlId="referencia">
                      <Form.Label className="mt-2 mb-0">Referência</Form.Label>
                      <Form.Control type="text" name="referencia" ref={register} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.referencia && errors.referencia.message}
                      </Col>
                    </Form.Group>
                    <Form.Group controlId="instrucoes">
                      <Form.Label className="mt-2 mb-0">Instruções bancárias adicionais</Form.Label>
                      <Form.Control type="text" maxLength={55} name="instrucoes" ref={register} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.instrucoes && errors.instrucoes.message}
                      </Col>
                    </Form.Group>
                    <br />
                    <Form.Label className="text-danger fs-6">* Campo obrigatório</Form.Label>
                  </>
                )}
              </Col>
              {sacado.cpf && (
                <Col xs="3">
                  <Form.Group controlId="vencimento" className="d-flex flex-column align-items-end">
                    <Form.Label className="mt-2 mb-0">
                      Vencimento <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      name="vencimento"
                      type="date"
                      ref={register({
                        required: { value: true, message: 'Informe um vencimento para o boleto.' },
                        validate: value => moment(value) >= moment().startOf('day') || 'Vencimento deve ser igual ou posterior à data atual.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.vencimento && errors.vencimento.message}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="valor" className="d-flex flex-column align-items-end">
                    <Form.Label className="mt-2 mb-0">
                      Valor original <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label> (a)
                    </Form.Label>
                    <Controller
                      render={props => (
                        <Form.Control
                          className="d-flex justify-content-end"
                          type="text"
                          placeholder="Valor"
                          value={props.value}
                          onChange={e => props.onChange(formatarValor(e.target.value))}
                          ref={props.ref} // wire up the input ref
                        />
                      )}
                      name="valor"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: 'É necessário informar o valor do boleto.',
                        validate: value => validarValor(value) || 'Valor inválido.',
                      }}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.valor && errors.valor.message}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="desconto" className="d-flex flex-column align-items-end">
                    <Form.Label className="mt-2 mb-0">Desconto</Form.Label>
                    <Controller
                      render={props => (
                        <Form.Control
                          type="text"
                          placeholder="Desconto"
                          value={props.value}
                          onChange={e => props.onChange(formatarValor(e.target.value))}
                          ref={props.ref} // wire up the input ref
                        />
                      )}
                      name="desconto"
                      defaultValue=""
                      control={control}
                      rules={{
                        validate: value => validarDesconto(value) || 'Valor inválido.',
                      }}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.desconto && errors.desconto.message}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="validadeDesconto" className="d-flex flex-column align-items-end">
                    <Form.Label className="mt-2 mb-0">Validade do desconto</Form.Label>
                    <Form.Control
                      name="validadeDesconto"
                      type="date"
                      ref={register({
                        validate: value =>
                          validarDataDesconto(value) || 'Data do desconto deve ser anterior ao vencimento e posterior à data atual.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.validadeDesconto && errors.validadeDesconto.message}
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="deducoes" className="d-flex flex-column align-items-end">
                    <Form.Label className="mt-2 mb-0">Outras deduções (b)</Form.Label>
                    <Controller
                      render={props => (
                        <Form.Control
                          type="text"
                          placeholder="Outras deduções"
                          value={props.value}
                          onChange={e => props.onChange(formatarValor(e.target.value))}
                          ref={props.ref} // wire up the input ref
                        />
                      )}
                      name="deducoes"
                      defaultValue=""
                      control={control}
                      rules={{
                        validate: value => validarValor(value, true) || 'Valor inválido.',
                      }}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.deducoes && errors.deducoes.message}
                    </Col>
                  </Form.Group>
                  <br />
                  <hr />
                  <Form.Group controlId="total" className="d-flex flex-column align-items-end">
                    <Form.Label className="mt-2 mb-0">Total (a - b)</Form.Label>
                    <Controller
                      render={props => (
                        <Form.Control
                          disabled
                          type="text"
                          value={props.value}
                          onChange={props.onChange}
                          ref={props.ref} // wire up the input ref
                        />
                      )}
                      name="total"
                      defaultValue=""
                      control={control}
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="success" type="submit">
                  Emitir boleto
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
});
