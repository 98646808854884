import axios from 'axios';

import { isDomesticServer, validarTipo } from '../utils';
import { api } from '../constants';
import { Auth } from '.';

export default class Requisicoes {
  /**
   * Cria uma instância singleton da classe Requisicoes.
   * Esta implementação é necessária para a utilização correta dos tokens no momento exato de sua alteração.
   * @memberof Requisicoes
   */
  constructor() {
    if (!Requisicoes.instance) {
      Requisicoes.instance = this;
    }

    return Requisicoes.instance;
  }
  /**
   * configureAxios
   *
   * @static
   * @param {string} apiKey
   * @param {string} appId
   * @param {string} token
   * @param {string} uid
   * @param {string} instanceId
   * @memberof Requisicoes
   */
  configureAxios(apiKey, appId, token, uid, instanceId = 'PAINEL-ADMIN') {
    return new Promise((resolve, reject) => {
      try {
        // remove qualquer interceptor que existe previamente
        if (this.intercept !== undefined) {
          axios.interceptors.request.eject(this.intercept);
        }
        // cria um novo interceptor e registra seu id no atributo intercept
        this.intercept = axios.interceptors.request.use(
          req => {
            // Não entrega o token caso o servidor não seja doméstico
            if (isDomesticServer(req.url)) {
              req.headers.token = `${apiKey}`;
              req.headers.id = `${appId}`;
              req.headers['x-access-token'] = `${token || ''}`;
              req.headers.uid = `${uid || ''}`;
              req.headers.instanceId = `${instanceId || ''}`;
            }
            return req;
          },
          error => {
            throw error;
          },
        );
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * releaseAxios
   *
   * @memberof Requisicoes
   */
  releaseAxios() {
    if (this.intercept !== undefined) {
      axios.interceptors.request.eject(this.intercept);
    }
  }

  /**
   * requisitar
   *
   * @static
   * @param {string} type    - verbo da requisição [GET, POST, PUT...]
   * @param {string} URI     - URI completo do endpoint
   * @param {object} body    - corpo da requisição
   * @memberof Requisicoes
   */
  static async requisitar(type, URI, body = {}) {
    try {
      if (!validarTipo(type, 'string') || !validarTipo(URI, 'string') || !validarTipo(body, 'object')) return false;
      const raw = await axios({
        method: type.toLowerCase(),
        url: URI,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: type.toLowerCase() === 'get' ? null : body,
        validateStatus: status =>
          (status >= 200 && status < 300) || status === 302 || status === 304 || status === 401 || status === 403 || status === 409,
      });
      if (raw.data.auth === false) {
        Auth.sair();
      }
      return raw.data;
    } catch (error) {
      console.warn('Erro na requisição:', error);
      const erro = { httpStatusCode: 500, data: `Não foi possível estabelecer conexão com o servidor. ${error}` };
      return erro;
    }
  }

  /**
   * tratarResposta
   *
   * @static
   * @param {*} response
   * @param {*} url
   * @memberof Requisicoes
   */
  static tratarResposta(response, url) {
    try {
      if (!response) return { erro: 'Erro ao receber resposta do servidor.' };
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200) return data;
      if (httpStatusCode === 401 || httpStatusCode === 403 || httpStatusCode === 409)
        return { erro: 'Usuário desconectado. Realize o login novamente.' };
      if (httpStatusCode === 412 || httpStatusCode === 428) return { erro: `${data}` };
      if (httpStatusCode >= 500) return { erro: `${data}. URL: ${url}` };
      return { erro: `Erro desconhecido ao se comunicar com o servidor. URL: ${url}`, data };
    } catch (error) {
      return { erro: `Erro de comunicação. ${error.message ? error.message.toString().substring(0, 50).concat('... ') : ''}URL: ${url}` };
    }
  }

  /**
   * getInfo
   *
   * @static
   * @memberof Requisicoes
   */
  static async getInfo() {
    const servico = api.INFO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postGerarLog
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postGerarLog(body) {
    const servico = api.LOGS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getIsFunci
   *
   * @static
   * @param {string} email
   * @memberof Requisicoes
   */
  static async getIsFunci(email) {
    const servico = api.IS_FUNCI;
    const url = servico.url(email);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getTurnos
   *
   * @static
   * @memberof Requisicoes
   */
  static async getTurnos() {
    const servico = api.TURNOS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getSocioPorTitulo
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getSocioPorTitulo(titulo) {
    const servico = api.SOCIO_CONSULTAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarPessoa
   *
   * @static
   * @param {string|number} id
   * @memberof Requisicoes
   */
  static async getConsultarPessoa(id) {
    const servico = api.PESSOAS_CONSULTAR;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getBuscarPessoas
   *
   * @static
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Requisicoes
   */
  static async getBuscarPessoas(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    const servico = api.PESSOAS_BUSCAR;
    const url = servico.url(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postIncluirPessoas
   *
   * @static
   * @param {object} pessoa
   * @memberof Requisicoes
   */
  static async postIncluirPessoas(pessoa) {
    const servico = api.PESSOAS_INCLUIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, pessoa);
    return this.tratarResposta(response, url);
  }

  /**
   * getObterFoto
   *
   * @static
   * @param {string|number} id
   * @memberof Requisicoes
   */
  static async getObterFoto(id) {
    const servico = api.PESSOAS_FOTO;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesAvulsosDispPorTitulo
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getConvitesAvulsosDispPorTitulo(titulo) {
    const servico = api.CONVITES_DISP_AVULSO;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesChurrasDispPorTitulo
   *
   * @static
   * @param {string} ano
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getConvitesChurrasDispPorTitulo(ano, titulo) {
    const servico = api.CONVITES_DISP_CHURRAS;
    const url = servico.url(ano, titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesChurrasDispPorReserva
   *
   * @static
   * @param {string} idReserva
   * @param {string} ano
   * @memberof Requisicoes
   */
  static async getConvitesChurrasDispPorReserva(idReserva, ano) {
    const servico = api.CONVITES_DISP_CHURRAS_RESERVA;
    const url = servico.url(idReserva, ano);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postEmitirConvite
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postEmitirConvite(body) {
    const servico = api.CONVITES_EMITIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarConvites
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getListarConvites(titulo) {
    const servico = api.CONVITES_LISTAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarConvitesCriterio
   *
   * @static
   * @param {string} criterio
   * @memberof Requisicoes
   */
  static async getListarConvitesCriterio(criterio) {
    const servico = api.CONVITES_LISTAR_CRITERIO;
    const url = servico.url(criterio);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarConvite
   *
   * @static
   * @param {string} idConvite
   * @memberof Requisicoes
   */
  static async getConsultarConvite(idConvite) {
    const servico = api.CONVITE;
    const url = servico.url(idConvite);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postConfirmarEntrada
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postConfirmarEntrada(body) {
    const servico = api.CONVITE_ENTRADA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarConvite
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarConvite(body) {
    const servico = api.CONVITE_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getChurrasqueiras
   *
   * @static
   * @memberof Requisicoes
   */
  static async getChurrasqueiras() {
    const servico = api.CHURRASQUEIRAS_LISTAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getChurrasqueiraPorId
   *
   * @static
   * @param {string} idChurrasqueira
   * @memberof Requisicoes
   */
  static async getChurrasqueiraPorId(idChurrasqueira) {
    const servico = api.CHURRASQUEIRAS_CONSULTAR;
    const url = servico.url(idChurrasqueira);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarChurrasqueira
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarChurrasqueira(body) {
    const servico = api.CHURRASQUEIRAS_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarReservas
   *
   * @static
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getListarReservas(dataInicial, dataFinal, titulo) {
    const servico = api.RESERVAS_LISTAR;
    const url = servico.url(dataInicial, dataFinal, titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarReservaPorId
   *
   * @static
   * @param {string} idReserva
   * @memberof Requisicoes
   */
  static async getConsultarReservaPorId(idReserva) {
    const servico = api.RESERVAS_CONSULTAR;
    const url = servico.url(idReserva);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarReserva
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarReserva(body) {
    const servico = api.RESERVAS_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getChurrasqDispPorData
   *
   * @static
   * @param {string} data
   * @memberof Requisicoes
   */
  static async getChurrasqDispPorData(data) {
    const servico = api.RESERVAS_DISP_CHURRASQ;
    const url = servico.url(data);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesPorIdReserva
   *
   * @static
   * @param {string} idReserva
   * @memberof Requisicoes
   */
  static async getConvitesPorIdReserva(idReserva) {
    const servico = api.RESERVAS_CONVITES;
    const url = servico.url(idReserva);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postConviteEnviarEmail
   *
   * @static
   * @param {string} convite
   * @memberof Requisicoes
   */
  static async postConviteEnviarEmail(convite) {
    const servico = api.CONVITE_EMAIL;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, convite);
    return this.tratarResposta(response, url);
  }

  /**
   * getReservasDatasDisp
   *
   * @static
   * @memberof Requisicoes
   */
  static async getReservasDatasDisp() {
    const servico = api.RESERVAS_DISP_DATAS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postEmitirReserva
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postEmitirReserva(body) {
    const servico = api.RESERVAS_EMITIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarUsuariosSistema
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getListarUsuariosSistema(titulo) {
    const servico = api.USUARIOS_SIS_LISTAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarUsuarioSistema
   *
   * @static
   * @param {string} uid
   * @memberof Requisicoes
   */
  static async getConsultarUsuarioSistema(uid) {
    const servico = api.USUARIOS_SIS_CONSULTAR;
    const url = servico.url(uid);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarPerfisUsuario
   *
   * @static
   * @param {string} uid
   * @memberof Requisicoes
   */
  static async getListarPerfisUsuario(uid) {
    const servico = api.USUARIOS_SIS_PERFIS_USUARIO;
    const url = servico.url(uid);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarPerfisSistema
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarPerfisSistema() {
    const servico = api.USUARIOS_SIS_PERFIS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarUsuarioSistema
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarUsuarioSistema(body) {
    const servico = api.USUARIOS_SIS_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarAcessosFisicos
   *
   * @static
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string} tipoIdentificador
   * @param {string} identificador
   * @memberof Requisicoes
   */
  static async getListarAcessosFisicos(dataInicial, dataFinal, tipoIdentificador, identificador) {
    const servico = api.ACESSOS_LISTAR;
    const url = servico.url(dataInicial, dataFinal, tipoIdentificador, identificador);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postRegistrarAcessoFisicos
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async postRegistrarAcessoFisicos(body) {
    const servico = api.ACESSOS_REGISTRO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getBuscarExamesMedicos
   *
   * @static
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Requisicoes
   */
  static async getBuscarExamesMedicos(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    const servico = api.EXAMES_BUSCAR;
    const url = servico.url(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getBuscarExamesMedicosPorData
   *
   * @static
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Requisicoes
   */
  static async getBuscarExamesMedicosPorData(dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    const servico = api.EXAMES_BUSCAR_POR_DATA;
    const url = servico.url(dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarExameMedico
   *
   * @static
   * @param {string|number} id
   * @memberof Requisicoes
   */
  static async getConsultarExameMedico(id) {
    const servico = api.EXAMES_CONSULTAR;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarExameMEdico
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarExameMedico(body) {
    const servico = api.EXAMES_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * postIncluirExameMEdico
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postIncluirExameMedico(body) {
    const servico = api.EXAMES_INCLUIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getSocioEDepPorTitulo
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getSocioEDepPorTitulo(titulo) {
    const servico = api.SOCIO_E_DEP_BUSCAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getBuscarRestricoesPorCriterio
   *
   * @static
   * @param {string} criterio
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Requisicoes
   */
  static async getBuscarRestricoesPorCriterio(criterio, dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    const servico = api.RESTRICOES_CONSULTAR;
    const url = servico.url(criterio, dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postIncluirRestricao
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postIncluirRestricao(body) {
    const servico = api.RESTRICOES_INCLUIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarRestricao
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarRestricao(body) {
    const servico = api.RESTRICOES_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getBuscarBoletosPorCriterio
   *
   * @static
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Requisicoes
   */
  static async getBuscarBoletosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    const servico = api.BOLETOS_LISTAR;
    const url = servico.url(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getBoletoPorId
   *
   * @static
   * @param {string} id
   * @param {boolean} consultarIf
   * @memberof Requisicoes
   */
  static async getBoletoPorId(id, consultarIf) {
    const servico = api.BOLETOS_CONSULTAR;
    const url = servico.url(id, consultarIf);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postIncluirBoleto
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postIncluirBoleto(body) {
    const servico = api.BOLETOS_INCLUIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarBoleto
   *
   * @static
   * @param {string} id
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarBoleto(id, body) {
    const servico = api.BOLETOS_ALTERAR;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getBoletoPdfPorId
   *
   * @static
   * @param {string} id
   * @memberof Requisicoes
   */
  static async getBoletoPdfPorId(id) {
    const servico = api.BOLETOS_PDF;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    // METODO NÃO TRATA A RESPOSTA POR SE TRATAR DE BINÁRIO
    return response;
  }

  /**
   * postBoletoEnviarEmail
   *
   * @static
   * @param {string} idBoleto
   * @param {object} body { email }
   * @memberof Requisicoes
   */
  static async postBoletoEnviarEmail(idBoleto, body) {
    const servico = api.BOLETOS_EMAIL;
    const url = servico.url(idBoleto);
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }
}
