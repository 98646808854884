import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, Col, Tabs, Tab, Form, Table, Badge, Row } from 'react-bootstrap';
import { Funci } from '../../services';
import { Carregando, Alerta } from '../../components';
import modelConvite from '../../models/modelConvite';
import { withOrdenar } from '../../utils';

export default withRouter(props => {
  const { history } = props;
  const { paramCriterio } = useParams();
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedCriterio, setValidatedCriterio] = useState(false);
  const [check, setCheck] = useState({ aberto: true, utilizado: true, nUtilizado: true, cancelado: false });
  const [criterio, setCriterio] = useState('');
  const [convites, setConvites] = useState([]);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      history.push(`/convites/${criterio || 0}`);
    }
    setValidatedCriterio(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      fecharAlerta();
      try {
        if (!paramCriterio || paramCriterio === '0') return null;
        const consulta = await Funci.listarConvitesCriterio(`${paramCriterio}`);

        if (consulta.erro) {
          setConvites([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setConvites([]);
            alertar('warning', 'Nenhum convite encontrado.');
          }
          if (consulta.length > 0)
            setConvites(
              withOrdenar(consulta.filter(c => c.tipo === 'A').map(c => modelConvite(c)))
                .ordenar('statusLabel', 'A')
                .ordenar('dataInicial', 'D', 'DD/MM/YYYY'),
            );
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [paramCriterio]);

  const filtrar = convs => {
    if (convs.length > 0) {
      return convs.filter(
        c =>
          (c.status === 'A' && check.aberto) ||
          (c.status === 'U' && check.utilizado) ||
          (c.status === 'N' && check.nUtilizado) ||
          (c.status === 'C' && check.cancelado),
      );
    } else {
      return [];
    }
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      {alerta.show ? (
        <Row className="justify-content-between">
          <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} />
        </Row>
      ) : null}

      <Tabs defaultActiveKey="convites" id="uncontrolled-tab-example">
        {/** ************************************************************************************************************************************* */}
        {/** * RESERVAS                                                                                                                            */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={false} variant="pills" eventKey="convites" title="Convites Avulsos">
          <Row className="px-2">
            <Col xs={3}>
              <LinkContainer to="/novoconviteavulso">
                <Button variant="success" className="mt-2">
                  Emitir convite
                </Button>
              </LinkContainer>
            </Col>
            <Col className="justify-content-end">
              <Form
                noValidate
                validated={validatedCriterio}
                onSubmit={handleSubmit}
                className="mt-2"
                action={`/convites/${criterio || 0}`}
                method="get"
              >
                <Row className="justify-content-end">
                  <Form.Group as={Col} xs={6} controlId="titulo" className="align-middle mb-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Título, CPF ou nome do convidado"
                      value={criterio}
                      onChange={event => setCriterio(event.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Critério não informado</Form.Control.Feedback>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Form inline="true" className="justify-content-center">
            <Form.Group controlId="status">
              <Form.Check
                inline="true"
                type="checkbox"
                id="A"
                label="Em aberto"
                checked={check.aberto}
                onChange={event => setCheck({ ...check, aberto: event.target.checked })}
              />
              <Form.Check
                inline="true"
                type="checkbox"
                id="U"
                label="Utilizado"
                checked={check.utilizado}
                onChange={event => setCheck({ ...check, utilizado: event.target.checked })}
              />
              <Form.Check
                inline="true"
                type="checkbox"
                id="N"
                label="Não utilizado"
                checked={check.nUtilizado}
                onChange={event => setCheck({ ...check, nUtilizado: event.target.checked })}
              />
              <Form.Check
                inline="true"
                type="checkbox"
                id="C"
                label="Cancelado"
                checked={check.cancelado}
                onChange={event => setCheck({ ...check, cancelado: event.target.checked })}
              />
            </Form.Group>
          </Form>
          <br />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th>Data permitida</th>
                <th>Utilização</th>
                <th>Título</th>
                <th>Nome Convidado</th>
                <th>CPF</th>
                <th>Menor?</th>
                <th>Status</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {filtrar(convites).map(c => (
                <tr key={`${c.idConvite}`}>
                  <td>{`${c.dataInicial} a ${c.dataFinal}`}</td>
                  <td>{c.dataUtilizacao}</td>
                  <td>{c.titulo}</td>
                  <td>{c.nomeConvidado}</td>
                  <td>{c.cpf}</td>
                  <td>{c.menorAdolescente ? 'SIM' : 'NÃO'}</td>
                  <td className={`${{ A: 'text-primary', U: 'text-success', N: 'text-warning', C: 'text-danger' }[c.status]}`}>{c.statusLabel}</td>
                  <td>
                    <LinkContainer to={`/detalheconvite/${c.idConvite}`}>
                      <Badge variant="secondary">Clique aqui</Badge>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Container>
  );
});
