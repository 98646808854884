import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Campo, Carregando, Foto, Voltar } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import { modelExameMedico, modelPessoa } from '../../models';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

export default withRouter(props => {
  const { history } = props;
  const { id } = useParams();
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const [pessoa, setPessoa] = useState(null);

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.consultarPessoa(id);
        console.log('id', id);
        console.log('consulta', consulta);
        if (consulta.erro) {
          setPessoa({});
          alertar('danger', consulta.erro);
        } else {
          setPessoa(consulta);
          // setPessoa(modelPessoa(consulta));
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [atualizar]);

  const statusColors = {
    ATIVO: 'text-success',
    INATIVO: 'text-secondary',
    CANCELADO: 'text-danger',
  };

  return (
    <>
      <Col as="div" className="rolagem">
        <Container fluid>
          <Carregando show={carregando} />
          {/* <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
          <ConfirmacaoEmail show={boxEmail.show} onConfirm={boxEmail.onConfirm} onCancel={boxEmail.onCancel} /> */}
          <br />
          <Row className="justify-content-between">
            <Voltar />
          </Row>

          <Card>
            <Card.Header as="h6">Cadastro</Card.Header>
            <Card.Body>
              {pessoa && (
                <>
                  <Row>
                    <Col className="d-flex align-items-center">
                      <Card.Text className="fw-bold">
                        {pessoa.pronome && `${pessoa.pronome} `}
                        {`${pessoa.nome || ''} `}
                        {pessoa.nome_rdzd_fantasia && `(${pessoa.nome_rdzd_fantasia})`}
                      </Card.Text>
                    </Col>
                    <Col xs={3}>
                      <div style={{ height: 150 }}>
                        <Foto id={`${pessoa.id}`} height={150} />
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Campo label="CPF:" valor={pessoa.cpf_cnpj} />
                    <Campo label="RG:" valor={`${pessoa.rg_ie || ''} ${pessoa.rg_orgao || ''} ${pessoa.rg_uf || ''}`.trim()} />
                    <Campo label="Nasc.:" valor={pessoa.data_nasc_const} />
                  </Row>
                  <Row>
                    <Campo label="Gênero:" valor={pessoa.genero} />
                    <Campo label="Estado Civil:" valor={pessoa.est_civil} />
                    <Campo label="Grau de Instrução:" valor={pessoa.grau_instrucao} />
                  </Row>
                  <Row>
                    <Campo label="Nome da Mãe:" valor={pessoa.nome_mae} />
                    <Campo label="Nome do Pai:" valor={pessoa.nome_pai} />
                    <Campo label="Naturalidade:" valor={pessoa.naturalidade} />
                  </Row>
                  <Row>
                    <Campo label="Email:" valor={pessoa.email} />
                    <Campo />
                    <Campo label="Status:" valor={pessoa.status} />
                  </Row>
                  <hr />
                  <Row>
                    <Campo label="Endereços" />
                  </Row>
                  <br />
                  {pessoa.enderecos.length > 0 &&
                    pessoa.enderecos.map((e, index) => (
                      <React.Fragment key={`${index}-${e.id}`}>
                        <Row>
                          <Campo label="Principal?" valor={e.in_principal} />
                          <Campo label="Tipo:" valor={e.tipo} />
                          <Campo label="Status:" valor={e.status} />
                        </Row>
                        <Row>
                          <Campo label="Logradouro:" valor={e.logradouro} />
                          <Campo label="Número:" valor={e.numero} />
                          <Campo label="Complemento:" valor={e.complemento} />
                        </Row>
                        <Row>
                          <Campo label="Bairro:" valor={e.bairro} />
                          <Campo label="Cidade:" valor={e.cidade} />
                          <Campo label="UF:" valor={e.uf} />
                        </Row>
                        <Row>
                          <Campo label="Cep:" valor={e.cep} />
                          <Campo label="Data de inclusão:" valor={e.timestamp} />
                          <Campo label="Origem Aclon?" valor={e.in_legado} />
                        </Row>
                        <br />
                      </React.Fragment>
                    ))}
                  <hr />
                  <Row>
                    <Campo label="Associações" />
                  </Row>
                  <br />
                  {pessoa.associacoes.length > 0 &&
                    pessoa.associacoes.map((a, index) => (
                      <React.Fragment key={`${index}-${a.titulo}`}>
                        <Row>
                          <Campo label="Título:" valor={a.titulo} />
                          <Campo label="Categoria:" valor={a.categoria} />
                          <Campo label="Data:" valor={a.timestamp} />
                          <Campo label="Status:" valor={a.status} />
                        </Row>
                      </React.Fragment>
                    ))}
                  {pessoa.exames.length > 0 && (
                    <>
                      <hr />
                      <Row>
                        <Campo label="Exames Médicos" />
                      </Row>
                      <br />
                      {pessoa.exames.map((a, index) => (
                        <React.Fragment key={`${index}-${a.id}`}>
                          <Row>
                            <Campo label="Emissão:" valor={modelExameMedico(a).dataEmissao} />
                            <Campo label="Validade:" valor={modelExameMedico(a).dataValidade} />
                            <Campo label="Status:" valor={modelExameMedico(a).statusExameLabel} />
                          </Row>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  <hr />
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                        Voltar
                      </Button>
                      <Button className="mx-2" variant="success" onClick={() => {}}>
                        Editar
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Col>
    </>
  );
});
