import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { Voltar, Carregando, Confirmacao, ConfirmacaoEmail } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarValor, gerarTextoInstrucoes, validarEmail } from '../../utils';
import { modelBoleto } from '../../models';
import { api } from '../../constants';

export default withRouter(props => {
  const { history, location } = props;
  const { id } = useParams();
  const { consultarIf = true } = location.state;
  const [atualizar, setAtualizar] = useState(0);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [boxEmail, setBoxEmail] = useState({ show: false });
  const [carregando, setCarregando] = useState(true);
  const [boleto, setBoleto] = useState(null);

  const statusColors = {
    PENDENTE: 'text-secondary',
    PROCESSANDO: 'text-secondary',
    PROTESTO: 'text-secondary',
    CANCELADO: 'text-danger',
    REGISTRADO: 'text-primary',
    PAGO: 'text-success',
    'EM LIQUIDAÇÃO': 'text-success',
    BAIXADO: 'text-warning',
  };
  const disableBotoes = {
    PENDENTE: { canc: false, imp: true, mail: true, baixa: true },
    PROCESSANDO: { canc: false, imp: true, mail: true, baixa: true },
    CANCELADO: { canc: true, imp: true, mail: true, baixa: true },
    REGISTRADO: { canc: false, imp: false, mail: false, baixa: false },
    PAGO: { canc: true, imp: true, mail: true, baixa: true },
    BAIXADO: { canc: true, imp: true, mail: true, baixa: true },
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const abrirBoxEmail = (onConfirm = () => {}) => {
    setBoxEmail({ onConfirm, onCancel: fecharBoxEmail, show: true });
  };

  const fecharBoxEmail = () => {
    setBoxEmail({ onConfirm: () => {}, onCancel: fecharBoxEmail, show: false });
  };

  const enviarPorEmail = async email => {
    try {
      fecharConfirmacao();
      fecharBoxEmail();
      setCarregando(true);
      if (!validarEmail(email)) return alertar('danger', 'Email inválido.');
      const obj = { email };
      const envio = await Funci.enviarBoletoPorEmail(id, obj);
      if (envio.erro) alertar('danger', envio.erro);
      if (envio === true) {
        alertar('success', 'Email encaminhado com sucesso.');
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  const cancelarBoleto = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.cancelarBoleto(id);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const baixarBoleto = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.baixarBoleto(id, 'Baixa por solicitação.', 1);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const consulta = await Funci.consultarBoletoPorId(id, consultarIf);
        if (consulta.erro) alertar('danger', consulta.erro);
        if (consulta.id) {
          const b = modelBoleto(consulta);
          setBoleto(b);
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, consultarIf, atualizar]);

  return (
    <>
      <Col as="div" className="rolagem">
        <Container fluid>
          <Carregando show={carregando} />
          <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
          <ConfirmacaoEmail show={boxEmail.show} onConfirm={boxEmail.onConfirm} onCancel={boxEmail.onCancel} />
          <br />
          <Row className="justify-content-between">
            <Voltar to="/boletos/0" />
          </Row>

          <Card>
            <Card.Header as="h6">Detalhe Boleto</Card.Header>
            <Card.Body>
              {boleto && (
                <>
                  <Row>
                    <Col>
                      <Card.Text className="fw-bold">{`Número do documento: ${boleto.nrDocumento || ''}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Banco: ${boleto.ifNome || ''}`}</Card.Text>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Card.Text className="fw-bold">
                        {`Status: `}
                        <span className={`${statusColors[boleto.statusLabel] || 'text-secondary'}`}>{`${boleto.statusLabel}`}</span>
                      </Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Card.Text className="fw-bold">{`Sacado${boleto.inSocio === 'S' && ' (Sócio)'}:`}</Card.Text>
                    <Col>
                      <Card.Text>
                        {`Nome: ${boleto.nomeRazao || ''}`}
                        <br />
                        {`CPF: ${boleto.cpfCnpj || ''}`}
                        <br />
                        {`${boleto.inSocio === 'S' ? ' Título: ' + boleto.titulo : ''}`}
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>
                        {`Endereço: ${boleto.enderecoLogradouro || ''} ${boleto.enderecoComplemento && '-'} ${boleto.enderecoComplemento || ''}`}
                        <br />
                        {`Cidade: ${boleto.enderecoCidade || ''} \u00A0\u00A0\u00A0\u00A0 UF: ${boleto.enderecoUf || ''}`}
                        <br />
                        {`CEP: ${boleto.enderecoCep || ''}`}
                      </Card.Text>
                    </Col>
                  </Row>
                  <hr />
                  <Card.Text className="fw-bold">Boleto</Card.Text>
                  <Row>
                    <Col>
                      <Card.Text>
                        {`Data de emissão: `}
                        <strong>{boleto.dtEmissao || ''}</strong>
                        <br />
                        {`Data de registro: ${boleto.dtRegistroBoleto || ''}`}
                        <br />
                        {`Vencimento: `}
                        <strong>{boleto.dtVcto || ''}</strong>
                        <br />
                        {`Data Recebimento: `}
                        <strong>{boleto.dtRecebimento || ''}</strong>
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>
                        {'Valor original: '}
                        <strong>R$ {formatarValor(boleto.vlOriginal)}</strong>
                        <br />
                        {'Outras deduções: '}
                        <strong>{formatarValor(boleto.vlAbatimento) ? 'R$ ' + formatarValor(boleto.vlAbatimento) : ''}</strong>
                        <br />
                        {'Desconto: '}
                        <strong>{formatarValor(boleto.descValor) ? 'R$ ' + formatarValor(boleto.descValor) : ''}</strong>
                        {'\u00A0\u00A0\u00A0\u00A0 Validade: '}
                        <strong>{boleto.descDtExp || ''}</strong>
                        <br />
                        {'Valor pago: '}
                        <strong>{formatarValor(boleto.vlPago) ? 'R$ ' + formatarValor(boleto.vlPago) : ''}</strong>
                      </Card.Text>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <Card.Text>
                        {`Referência: ${boleto.referencia || ''}`}
                        <br />
                        <br />
                        {'Instruções:'}
                        {gerarTextoInstrucoes(boleto, boleto.instrucoes).map((i, index) => (
                          <React.Fragment key={`${index}`}>
                            <br />
                            {i}
                          </React.Fragment>
                        ))}
                      </Card.Text>
                    </Col>
                  </Row>

                  <hr />
                  <Row>
                    <Col>
                      <Card.Text>{`Motivo baixa/cancelamento: ${boleto.motivoBaixa || ''}`}</Card.Text>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <Card.Text>{`Responsável pela emissão: ${boleto.nomeResponsavelRegistro || ''}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Responsável pela baixa/cancelamento: ${boleto.nomeResponsavelBaixa || ''}`}</Card.Text>
                    </Col>
                  </Row>
                </>
              )}
              <hr />
              {boleto && (
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                      Voltar
                    </Button>
                    <Button
                      className="mx-2"
                      variant="danger"
                      disabled={disableBotoes[boleto.statusLabel] ? disableBotoes[boleto.statusLabel].canc : true}
                      onClick={() => confirmar('Tem certeza que deseja cancelar o boleto?', cancelarBoleto)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className="mx-2"
                      variant="primary"
                      disabled={disableBotoes[boleto.statusLabel] ? disableBotoes[boleto.statusLabel].imp : true}
                      href={api.BOLETOS_PDF.url(id)}
                      target="_BLANK"
                    >
                      Imprimir
                    </Button>
                    <Button
                      className="mx-2"
                      variant="info"
                      disabled={disableBotoes[boleto.statusLabel] ? disableBotoes[boleto.statusLabel].mail : true}
                      onClick={() => abrirBoxEmail(enviarPorEmail)}
                    >
                      Enviar por email
                    </Button>
                    <Button
                      className="mx-2"
                      variant="warning"
                      disabled={disableBotoes[boleto.statusLabel] ? disableBotoes[boleto.statusLabel].baixa : true}
                      onClick={() => confirmar('Confirma a baixa do boleto? Não é possível desfazer esta ação.', baixarBoleto)}
                    >
                      Baixar
                    </Button>
                    <Button className="mx-2" variant="success" onClick={() => setAtualizar(atualizar + 1)}>
                      Atualizar página
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Col>
    </>
  );
});
